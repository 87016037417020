/*======= Common Start =========*/
export const SHOW_LOADER = 'SHOW_LOADER';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const UPDATE_PREV_ROUTE = 'UPDATE_PREV_ROUTE';
export const UPDATE_PAGE = 'UPDATE_PAGE';

export const SHOW_HIDE_SNACKBAR = 'SHOW_HIDE_SNACKBAR';
/* =========Common End======= */

/*===================== Noification =====================*/
export const USER_UNREAD_NOTIFICATION_PENDING = 'USER_UNREAD_NOTIFICATION_PENDING';
export const USER_UNREAD_NOTIFICATION_SUCCESS = 'USER_UNREAD_NOTIFICATION_SUCCESS';
export const USER_UNREAD_NOTIFICATION_FAILED = 'USER_UNREAD_NOTIFICATION_FAILED';

export const ADMIN_UNREAD_NOTIFICATION_PENDING = 'ADMIN_UNREAD_NOTIFICATION_PENDING';
export const ADMIN_UNREAD_NOTIFICATION_SUCCESS = 'ADMIN_UNREAD_NOTIFICATION_SUCCESS';
export const ADMIN_UNREAD_NOTIFICATION_FAILED = 'ADMIN_UNREAD_NOTIFICATION_FAILED';

export const FETCH_NOTIFICATION_PENDING = 'FETCH_NOTIFICATION_PENDING';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILED = 'FETCH_NOTIFICATION_FAILED';

export const FETCH_NOTIFICATION_ADMIN_PENDING = 'FETCH_NOTIFICATION_ADMIN_PENDING';
export const FETCH_NOTIFICATION_ADMIN_SUCCESS = 'FETCH_NOTIFICATION_ADMIN_SUCCESS';
export const FETCH_NOTIFICATION_ADMIN_FAILED = 'FETCH_NOTIFICATION_ADMIN_FAILED';

export const READ_NOTIFICATION_PENDING = 'READ_NOTIFICATION_PENDING';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED';

export const DELETE_NOTIFICATION_PENDING = 'DELETE_NOTIFICATION_PENDING';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

/*========================= Noification END ================*/

/*===================== Signin =====================*/
export const SIGIN_PENDING = 'SIGIN_PENDING';
export const SIGIN_SUCCESS = 'SIGIN_SUCCESS';
export const SIGIN_FAILED = 'SIGIN_FAILED';

export const SSO_LOGIN_PENDING = 'SSO_LOGIN_PENDING';
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS';
export const SSO_LOGIN_FAILED = 'SSO_LOGIN_FAILED';

export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const LOGOUT = 'LOGOUT';
/*========================= Signin END ================*/

/** ========================= Global Search ======================== **/
export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';
/** ======================= Global Search END ========================**/

/**=============================== OKR ================================**/
export const FETCH_OKR_PENDING = 'FETCH_OKR_PENDING';
export const FETCH_OKR_SUCCESS = 'FETCH_OKR_SUCCESS';
export const FETCH_OKR_FAILED = 'FETCH_OKR_FAILED';

export const FETCH_ALIGN_OKR_PENDING = 'FETCH_ALIGN_OKR_PENDING';
export const FETCH_ALIGN_OKR_SUCCESS = 'FETCH_ALIGN_OKR_SUCCESS';
export const FETCH_ALIGN_OKR_FAILED = 'FETCH_ALIGN_OKR_FAILED';

export const ADD_GOALS_PENDING = 'ADD_GOALS_PENDING';
export const ADD_GOALS_SUCCESS = 'ADD_GOALS_SUCCESS';
export const ADD_GOALS_FAILED = 'ADD_GOALS_FAILED';

export const EDIT_GOALS_PENDING = 'EDIT_GOALS_PENDING';
export const EDIT_GOALS_SUCCESS = 'EDIT_GOALS_SUCCESS';
export const EDIT_GOALS_FAILED = 'EDIT_GOALS_FAILED';

export const UPDATE_KR_PROGRESS_PENDING = 'UPDATE_KR_PROGRESS_PENDING';
export const UPDATE_KR_PROGRESS_SUCCESS = 'UPDATE_KR_PROGRESS_SUCCESS';
export const UPDATE_KR_PROGRESS_FAILED = 'UPDATE_KR_PROGRESS_FAILED';

export const FETCH_OKR_STATUS_PENDING = 'FETCH_OKR_STATUS_PENDING';
export const FETCH_OKR_STATUS_SUCCESS = 'FETCH_OKR_STATUS_SUCCESS';
export const FETCH_OKR_STATUS_FAILED = 'FETCH_OKR_STATUS_FAILED';
export const UPDATE_GOAL_STATUS = 'UPDATE_GOAL_STATUS';

export const RESET_OKR = 'RESET_OKR';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CURRENT_CYCLE_DATA = 'CURRENT_CYCLE_DATA';

export const ALIGN_GOALS_PENDING = 'ALIGN_GOALS_PENDING';
export const ALIGN_GOALS_SUCCESS = 'ALIGN_GOALS_SUCCESS';
export const ALIGN_GOALS_FAILED = 'ALIGN_GOALS_FAILED';

export const GET_OKR_MASTER_PENDING = 'GET_OKR_MASTER_PENDING';
export const GET_OKR_MASTER_SUCCESS = 'GET_OKR_MASTER_SUCCESS';
export const GET_OKR_MASTER_FAILED = 'GET_OKR_MASTER_FAILED';

/**=========================== OKR END ================================**/

/**========================== Feedback ================================**/
export const FETCH_FEEDBACK_PENDING = 'FETCH_FEEDBACK_PENDING';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_FAILED = 'FETCH_FEEDBACK_FAILED';

export const ASK_FEEDBACK_PENDING = 'ASK_FEEDBACK_PENDING';
export const ASK_FEEDBACK_SUCCESS = 'ASK_FEEDBACK_SUCCESS';
export const ASK_FEEDBACK_FAILED = 'ASK_FEEDBACK_FAILED';

export const GIVE_FEEDBACK_PENDING = 'GIVE_FEEDBACK_PENDING';
export const GIVE_FEEDBACK_SUCCESS = 'GIVE_FEEDBACK_SUCCESS';
export const GIVE_FEEDBACK_FAILED = 'GIVE_FEEDBACK_FAILED';

export const FEEDBACK_1_TO_1_PENDING = 'FEEDBACK_1_TO_1_PENDING';
export const FEEDBACK_1_TO_1_SUCCESS = 'FEEDBACK_1_TO_1_SUCCESS';
export const FEEDBACK_1_TO_1_FAILED = 'FEEDBACK_1_TO_1_FAILED';

export const COMMENT_FEEDBACK_PENDING = 'COMMENT_FEEDBACK_PENDING';
export const COMMENT_FEEDBACK_SUCCESS = 'COMMENT_FEEDBACK_SUCCESS';
export const COMMENT_FEEDBACK_FAILED = 'COMMENT_FEEDBACK_FAILED';

export const RESET_FEEDBACK = 'RESET_FEEDBACK';
/**========================= Feedback End =============================**/

/**========================== Organization ====================================**/
export const FETCH_ORG_PENDING = 'FETCH_ORG_PENDING';
export const FETCH_ORG_SUCCESS = 'FETCH_ORG_SUCCESS';
export const FETCH_ORG_FAILED = 'FETCH_ORG_FAILED';

export const ADD_ORG_PENDING = 'ADD_ORG_PENDING';
export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS';
export const ADD_ORG_FAILED = 'ADD_ORG_FAILED';
export const CALL_API = 'CALL_API';
export const UPDATE_ORG_LIST = 'UPDATE_ORG_LIST';

/**========================== Organization END ================================**/

/**========================== Roles ====================================**/
export const FETCH_ROLES_PENDING = 'FETCH_ROLES_PENDING';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const ADD_ROLES_PENDING = 'ADD_ROLES_PENDING';
export const ADD_ROLES_SUCCESS = 'ADD_ROLES_SUCCESS';
export const ADD_ROLES_FAILED = 'ADD_ROLES_FAILED';

export const EDIT_ROLES_PENDING = 'EDIT_ROLES_PENDING';
export const EDIT_ROLES_SUCCESS = 'EDIT_ROLES_SUCCESS';
export const EDIT_ROLES_FAILED = 'EDIT_ROLES_FAILED';

export const ASSIGN_ROLES_PENDING = 'ASSIGN_ROLES_PENDING';
export const ASSIGN_ROLES_SUCCESS = 'ASSIGN_ROLES_SUCCESS';
export const ASSIGN_ROLES_FAILED = 'ASSIGN_ROLES_FAILED';

export const GET_PERMISSION_PENDING = 'GET_PERMISSION_PENDING';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILED = 'GET_PERMISSION_FAILED';

export const UPDATE_ROLENAME_PENDING = 'UPDATE_ROLENAME_PENDING';
export const UPDATE_ROLENAME_SUCCESS = 'UPDATE_ROLENAME_SUCCESS';
export const UPDATE_ROLENAME_FAILED = 'UPDATE_ROLENAME_FAILED';

export const FETCH_ROLES_LIST_PENDING = 'FETCH_ROLES_LIST_PENDING';
export const FETCH_ROLES_LIST_SUCCESS = 'FETCH_ROLES_LIST_SUCCESS';
export const FETCH_ROLES_LIST_FAILED = 'FETCH_ROLES_LIST_FAILED';
export const UPDATE_ROLE_STATUS = 'UPDATE_ROLE_STATUS';
export const UPDATE_ROLES_LIST = 'UPDATE_ROLES_LIST';

/**========================== Roles END ================================**/

/**========================== Users ====================================**/
export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export const SEARCH_USERS_PENDING = 'SEARCH_USERS_PENDING';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED';

export const ADD_USERS_PENDING = 'ADD_USERS_PENDING';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAILED = 'ADD_USERS_FAILED';

export const EDIT_USERS_PENDING = 'EDIT_USERS_PENDING';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_FAILED = 'EDIT_USERS_FAILED';

export const FETCH_LICENCE_DETAIL_PENDING = 'FETCH_LICENCE_DETAIL_PENDING';
export const FETCH_LICENCE_DETAIL_SUCCESS = 'FETCH_LICENCE_DETAIL_SUCCESS';
export const FETCH_LICENCE_DETAIL_FAILED = 'FETCH_LICENCE_DETAIL_FAILED';

export const FETCH_ALL_USERS_PENDING = 'FETCH_ALL_USERS_PENDING';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAILED = 'FETCH_ALL_USERS_FAILED';

export const UPDATE_USERS_STATUS = 'UPDATE_USERS_STATUS';

export const REFETCH_LICENCE_DETAIL = 'REFETCH_LICENCE_DETAIL';

export const REFETCH_USER_DETAIL = 'REFETCH_USER_DETAIL';

/**========================== Users END ================================**/

/**======================= PEOPLE ======================================= */
export const FETCH_PEOPLE_PENDING = 'FETCH_PEOPLE_PENDING';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILED = 'FETCH_PEOPLE_FAILED';
export const RESET_PEOPLE_DATA = 'RESET_PEOPLE_DATA';
/** ====================================================================== */

/** ==================== ALIGN ================================== */
export const FETCH_ALIGN_PENDING = 'FETCH_ALIGN_PENDING';
export const FETCH_ALIGN_SUCCESS = 'FETCH_ALIGN_SUCCESS';
export const FETCH_ALIGN_FAILED = 'FETCH_ALIGN_FAILED';
export const RESET_ALIGNMENT = 'RESET_ALIGNMENT';
export const UPDATE_ALIGNMENT = 'UPDATE_ALIGNMENT';
export const FETCH_TEAM_ALIGN_PENDING = 'FETCH_TEAM_ALIGN_PENDING';
export const FETCH_TEAM_ALIGN_SUCCESS = 'FETCH_TEAM_ALIGN_SUCCESS';
export const FETCH_TEAM_ALIGN_FAILED = 'FETCH_TEAM_ALIGN_FAILED';
export const FETCH_DIRECT_REPORTS_PENDING = 'FETCH_DIRECT_REPORTS_PENDING';
export const FETCH_DIRECT_REPORTS_SUCCESS = 'FETCH_DIRECT_REPORTS_SUCCESS';
export const FETCH_DIRECT_REPORTS_FAILED = 'FETCH_DIRECT_REPORTS_FAILED';
/** ==================== ALIGN End ================================== */

/** ==================== PERSONAL FEEDBACK ================================== */
export const UPDATE_PERSONAL_FEEDBACK_ONE_ON_ONE = 'UPDATE_PERSONAL_FEEDBACK_ONE_ON_ONE';
/** ==================== END PERSONAL FEEDBACK ================================== */

export const UPDATE_GOAT_TYPE = 'UPDATE_GOAT_TYPE';

/** ================Filter types =========================*/
export const ADD_ALIGNMENT_FILTER = 'ADD_ALIGNMENT_FILTER';
export const ADD_ORGANISATION_FILTER = 'ADD_ORGANISATION_FILTER';
export const ADD_TEAMOKR_FILTER = 'ADD_TEAMOKR_FILTER';

/** ================ Alignment Map Action types =========================*/
export const ALIGNMENT_MAP_ACTION = 'ALIGNMENT_MAP_ACTION';
/**================= Fetch delta score ================== */
export const FETCH_DELTA_SCORE_PENDING = 'FETCH_DELTA_SCORE_PENDING';
export const FETCH_DELTA_SCORE_SUCCESS = 'FETCH_DELTA_SCORE_SUCCESS';
export const FETCH_DELTA_SCORE_FAILED = 'FETCH_DELTA_SCORE_FAILED';

/** ================ Direct Report Sort By =========================*/
export const ADD_DIRECTREPORT_SORTBY = 'ADD_DIRECTREPORT_SORTBY';

/**================= Fetch Recent Contributor score ================== */
export const FETCH_RECENT_CONTRIBUTION_PENDING = 'FETCH_RECENT_CONTRIBUTION_PENDING';
export const FETCH_RECENT_CONTRIBUTION__SUCCESS = 'FETCH_RECENT_CONTRIBUTION__SUCCESS';
export const FETCH_RECENT_CONTRIBUTION__FAILED = 'FETCH_RECENT_CONTRIBUTION__FAILED';

/** ========================= Show Guided tour =============================== */
export const SHOW_GUIDED_TOUR = 'SHOW_GUIDED_TOUR';
export const FETCH_GUIDED_DATA_PENDING = 'FETCH_GUIDED_DATA_PENDING';
export const FETCH_GUIDED_DATA_SUCCESS = 'FETCH_GUIDED_DATA_SUCCESS';
export const FETCH_GUIDED_DATA_FAILED = 'FETCH_GUIDED_DATA_FAILED';

/** Current Dashboard Tab */
export const CURRENT_DASHBOARD_TAB = 'CURRENT_DASHBOARD_TAB';
export const USER_CLOSED_GUIDED_TOUR = 'USER_CLOSED_GUIDED_TOUR';
export const CURRENT_DASHBOARD_TAB_FOR_SO = 'CURRENT_DASHBOARD_TAB_FOR_SO';
export const CURRENT_DASHBOARD_TAB_FOR_SO_NOTI_REDIRECTION = 'CURRENT_DASHBOARD_TAB_FOR_SO_NOTI_REDIRECTION';
export const NOTIFICATION_OKR_CYCLE = 'NOTIFICATION_OKR_CYCLE';

/** Onboarding */
export const FETCH_ONBOARDING_PENDING = 'FETCH_ONBOARDING_PENDING';
export const FETCH_ONBOARDING_SUCCESS = 'FETCH_ONBOARDING_SUCCESS';
export const FETCH_ONBOARDING_FAILED = 'FETCH_ONBOARDING_FAILED';
export const UPDATE_ONBOARDING_COUNT = 'UPDATE_ONBOARDING_COUNT';
export const ONBOARDING_COUNT_PENDING = 'ONBOARDING_COUNT_PENDING';
export const ONBOARDING_COUNT_SUCCESS = 'ONBOARDING_COUNT_SUCCESS';
export const ONBOARDING_COUNT_FAILED = 'ONBOARDING_COUNT_FAILED';
export const UPDATE_CALLOUT_PANEL = 'UPDATE_CALLOUT_PANEL';

/** Change cycle id */
export const CHANGE_CYCLE = 'CHANGE_CYCLE';

/** Archive data */
export const ARCHIVE_OKR_PENDING = 'ARCHIVE_OKR_PENDING';
export const ARCHIVE_OKR_SUCCESS = 'ARCHIVE_OKR_SUCCESS';
export const ARCHIVE_OKR_FAILED = 'ARCHIVE_OKR_FAILED';

/** Task data */
export const TASK_PENDING = 'TASK_PENDING';
export const TASK_SUCCESS = 'TASK_SUCCESS';
export const TASK_FAILED = 'TASK_FAILED';
export const RESET_TASK = 'RESET_TASK';

/** Dashboard Progress FOR ALL TAB  */
export const DASHBOARD_PROGRESS_PENDING = 'DASHBOARD_PROGRESS_PENDING';
export const DASHBOARD_PROGRESS_SUCCESS = 'DASHBOARD_PROGRESS_SUCCESS';
export const DASHBOARD_PROGRESS_FAILED = 'DASHBOARD_PROGRESS_FAILED';

/** Dashboard Progress for TEAMS OKR TAB  */
export const TEAMSOKR_PROGRESS_PENDING = 'TEAMSOKR_PROGRESS_PENDING';
export const TEAMSOKR_PROGRESS_SUCCESS = 'TEAMSOKR_PROGRESS_SUCCESS';
export const TEAMSOKR_PROGRESS_FAILED = 'TEAMSOKR_PROGRESS_FAILED';

/** Dashboard Progress for TEAMS OKR TAB  */
export const GET_PERMISSIONS_PENDING = 'GET_PERMISSIONS_PENDING';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILED = 'GET_PERMISSIONS_FAILED';

/** Update Role */
export const UPDATE_ROLE_PENDING = 'UPDATE_ROLE_PENDING';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';
export const UPDATE_ROLE_RESET = 'UPDATE_ROLE_RESET';
export const UPDATE_ROLE_STATUS_RESET = 'UPDATE_ROLE_STATUS_RESET';

/** Get Role */
export const GET_ROLE_PENDING = 'GET_ROLE_PENDING';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILED = 'GET_ROLE_FAILED';
export const RESET_CURRENT_ROLE = 'RESET_CURRENT_ROLE';

/** get organisations */
export const FETCH_ALL_ORGANISATIONS_PENDING = 'FETCH_ALL_ORGANISATIONS_PENDING';
export const FETCH_ALL_ORGANISATIONS_SUCCESS = 'FETCH_ALL_ORGANISATIONS_SUCCESS';
export const FETCH_ALL_ORGANISATIONS_FAILED = 'FETCH_ALL_ORGANISATIONS_FAILED';

/** get manage organization */
export const GET_MANAGE_ORGANISATIONS_PENDING = 'GET_MANAGE_ORGANISATIONS_PENDING';
export const GET_MANAGE_ORGANISATIONS_SUCCESS = 'GET_MANAGE_ORGANISATIONS_SUCCESS';
export const GET_MANAGE_ORGANISATIONS_FAILED = 'GET_MANAGE_ORGANISATIONS_FAILED';

/** Get employee permissions */
export const GET_USER_PERMISSION_PENDING = 'GET_USER_PERMISSION_PENDING';
export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
export const GET_USER_PERMISSION_FAILED = 'GET_USER_PERMISSION_FAILED';

/** Get employee permissions */
export const GET_ROLES_BY_ROLE_ID_PENDING = 'GET_ROLES_BY_ROLE_ID_PENDING';
export const GET_ROLES_BY_ROLE_ID_SUCCESS = 'GET_ROLES_BY_ROLE_ID_SUCCESS';
export const GET_ROLES_BY_ROLE_ID_FAILED = 'GET_ROLES_BY_ROLE_ID_FAILED';

/** Fetch cycle details */
export const FETCH_CYCLES_PENDING = 'FETCH_CYCLES_PENDING';
export const FETCH_CYCLES_SUCCESS = 'FETCH_CYCLES_SUCCESS';
export const FETCH_CYCLES_FAILED = 'FETCH_CYCLES_FAILED';

/** Get employee permissions */
export const IS_FEDERATION_USER_PENDING = 'IS_FEDERATION_USER_PENDING';
export const IS_FEDERATION_USER_SUCCESS = 'IS_FEDERATION_USER_SUCCESS';
export const IS_FEDERATION_USER_FAILED = 'IS_FEDERATION_USER_FAILED';

/** Get employee permissions */
export const GET_PARENT_ORGANISATION_PENDING = 'GET_PARENT_ORGANISATION_PENDING';
export const GET_PARENT_ORGANISATION_SUCCESS = 'GET_PARENT_ORGANISATION_SUCCESS';
export const GET_PARENT_ORGANISATION_FAILED = 'GET_PARENT_ORGANISATION_FAILED';
export const RESET_ORGANISATION = 'RESET_ORGANISATION';

/** Reports */
export const FETCH_TEAM_PROGRESS_PENDING = 'FETCH_TEAM_PROGRESS_PENDING';
export const FETCH_TEAM_PROGRESS_SUCCESS = 'FETCH_TEAM_PROGRESS_SUCCESS';
export const FETCH_TEAM_PROGRESS_FAILED = 'FETCH_TEAM_PROGRESS_FAILED';

/** Reports */
export const FETCH_LEADING_TEAM_PENDING = 'FETCH_LEADING_TEAM_PENDING';
export const FETCH_LEADING_TEAM_SUCCESS = 'FETCH_LEADING_TEAM_SUCCESS';
export const FETCH_LEADING_TEAM_FAILED = 'FETCH_LEADING_TEAM_FAILED';
export const RESET_LEADING_TEAM = 'RESET_LEADING_TEAM';

/** ORG Reports */
export const FETCH_ORG_REPORTS_PENDING = 'FETCH_ORG_REPORTS_PENDING';
export const FETCH_ORG_REPORTS_SUCCESS = 'FETCH_ORG_REPORTS_SUCCESS';
export const FETCH_ORG_REPORTS_FAILED = 'FETCH_ORG_REPORTS_FAILED';

export const FETCH_LEADING_TEAM_REPORTS_PENDING = 'FETCH_LEADING_TEAM_REPORTS_PENDING';
export const FETCH_LEADING_TEAM_REPORTS_SUCCESS = 'FETCH_LEADING_TEAM_REPORTS_SUCCESS';
export const FETCH_LEADING_TEAM_REPORTS_FAILED = 'FETCH_LEADING_TEAM_REPORTS_FAILED';
/** My Leading Teams */
export const FETCH_MY_LEADING_TEAM_PENDING = 'FETCH_MY_LEADING_TEAM_PENDING';
export const FETCH_MY_LEADING_TEAM_SUCCESS = 'FETCH_MY_LEADING_TEAM_SUCCESS';
export const FETCH_MY_LEADING_TEAM_FAILED = 'FETCH_MY_LEADING_TEAM_FAILED';

/**Notes */
export const FETCH_GET_NOTES_PENDING = 'FETCH_GET_NOTES_PENDING';
export const FETCH_GET_NOTES_SUCCESS = 'FETCH_GET_NOTES_SUCCESS';
export const FETCH_GET_NOTES_FAILED = 'FETCH_GET_NOTES_FAILED';
export const RESET_NOTES = 'RESET_NOTES';

/**Conversation */
export const FETCH_CONVERSATION_PENDING = 'FETCH_CONVERSATION_PENDING';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAILED = 'FETCH_CONVERSATION_FAILED';
export const RESET_CONVERSATION = 'RESET_CONVERSATION';

/**Checkins */
export const CHECKIN_STATUS_PENDING = 'CHECKIN_STATUS_PENDING';
export const CHECKIN_STATUS_SUCCESS = 'CHECKIN_STATUS_SUCCESS';
export const CHECKIN_STATUS_FAILED = 'CHECKIN_STATUS_FAILED';

/**
 * Signal R
 */
export const HIGHLIGHT_NOTIFICATION = 'HIGHLIGHT_NOTIFICATION';
export const SIGNALR_UPDATE_PROGRESS = 'SIGNALR_UPDATE_PROGRESS';
export const SIGNALR_CONTRIBUTOR_ADDED = 'SIGNALR_CONTRIBUTOR_ADDED';
export const SIGNALR_OKR_UPDATED = 'SIGNALR_OKR_UPDATED';
export const IS_DRAWER_DIALOG_OPENED = 'IS_DRAWER_DIALOG_OPENED';
export const SHOW_REFRESH_SNACKBAR = 'SHOW_REFRESH_SNACKBAR';
export const CALENDAR_OPEN_STATE = 'CALENDAR_OPEN_STATE';
export const IS_NON_DASHBOARD_UPDATED = 'IS_NON_DASHBOARD_UPDATED';
export const SIGNAL_R_CONNECTION_STATUS = 'SIGNAL_R_CONNECTION_STATUS';
export const CURRENT_ORG_CYCLE_ID = 'CURRENT_ORG_CYCLE_ID';
export const CYCLE_ID_DETAILS = 'CYCLE_ID_DETAILS';
export const USER_SELECTED_CYCLE = 'USER_SELECTED_CYCLE';
export const TEAM_CYCLE_DETAILS = 'TEAM_CYCLE_DETAILS';

/**Confidence */
export const CONFIDENCE_ALERT_PENDING = 'CONFIDENCE_ALERT_PENDING';
export const CONFIDENCE_ALERT_SUCCESS = 'CONFIDENCE_ALERT_SUCCESS';
export const CONFIDENCE_ALERT_FAILED = 'CONFIDENCE_ALERT_FAILED';

// Free Trial
export const FREE_TRIAL_PENDING = 'FREE_TRIAL_PENDING';
export const FREE_TRIAL_SUCCESS = 'FREE_TRIAL_SUCCESS';
export const FREE_TRIAL_FAILED = 'FREE_TRIAL_FAILED';
export const FREE_TRIAL_EMAILID_PENDING = 'FREE_TRIAL_EMAILID_PENDING';
export const FREE_TRIAL_EMAILID_SUCCESS = 'FREE_TRIAL_EMAILID_SUCCESS';
export const FREE_TRIAL_EMAILID_FAILED = 'FREE_TRIAL_EMAILID_FAILED';
export const FREE_TRIAL_DOMAIN_PENDING = 'FREE_TRIAL_DOMAIN_PENDING';
export const FREE_TRIAL_DOMAIN_SUCCESS = 'FREE_TRIAL_DOMAIN_SUCCESS';
export const FREE_TRIAL_DOMAIN_FAILED = 'FREE_TRIAL_DOMAIN_FAILED';
export const FREE_TRIAL_PASSWORD_PENDING = 'FREE_TRIAL_PASSWORD_PENDING';
export const FREE_TRIAL_PASSWORD_SUCCESS = 'FREE_TRIAL_PASSWORD_SUCCESS';
export const FREE_TRIAL_PASSWORD_FAILED = 'FREE_TRIAL_PASSWORD_FAILED';

export const IDLE_STATE_SESSION_EXPIRE = 'IDLE_STATE_SESSION_EXPIRE';
export const IMPERSONATE_TOAST_STATUS = 'IMPERSONATE_TOAST_STATUS';

// Notification Panel New Actions
export const UPDATE_NOTIFICATION_TAB_DATA = 'UPDATE_NOTIFICATION_TAB_DATA';

export const OKR_IMPORT_PENDING = 'OKR_IMPORT_PENDING';
export const OKR_IMPORT_SUCCESS = 'OKR_IMPORT_SUCCESS';
export const OKR_IMPORT_FAILED = 'OKR_IMPORT_FAILED';

export const OKR_IMPORT_CATEGORY_PENDING = 'OKR_IMPORT_CATEGORY_PENDING';
export const OKR_IMPORT_CATEGORY_SUCCESS = 'OKR_IMPORT_CATEGORY_SUCCESS';
export const OKR_IMPORT_CATEGORY_FAILED = 'OKR_IMPORT_CATEGORY_FAILED';
export const IS_PERSONATED_USER = 'IS_PERSONATED_USER';

//import
export const MY_OKR_IMPORT_PENDING = 'MY_OKR_IMPORT_PENDING';
export const MY_OKR_IMPORT_SUCCESS = 'MY_OKR_IMPORT_SUCCESS';
export const MY_OKR_IMPORT_FAILED = 'MY_OKR_IMPORT_FAILED';

// About Us User Actions
export const IS_VERSION_PENDING = 'IS_VERSION_PENDING';
export const IS_VERSION_SUCCESS = 'IS_VERSION_SUCCESS';
export const IS_VERSION_FAILED = 'IS_VERSION_FAILED';

// Current Cycle Upgrade
export const IS_CYCLE_CHANGED = 'IS_CYCLE_CHANGED';

// About Us User Actions
export const FECTH_CHECK_IN_MASTERDATA_PENDING = 'FECTH_CHECK_IN_MASTERDATA_PENDING';
export const FECTH_CHECK_IN_MASTERDATA_SUCCESS = 'FECTH_CHECK_IN_MASTERDATA_SUCCESS';
export const FECTH_CHECK_IN_MASTERDATA_FAILED = 'FECTH_CHECK_IN_MASTERDATA_FAILED';

/** User version*/
export const USER_VERSION_STATUS_PENDING = 'USER_VERSION_STATUS_PENDING';
export const USER_VERSION_STATUS_SUCCESS = 'USER_VERSION_STATUS_SUCCESS';
export const USER_VERSION_STATUS_FAILED = 'USER_VERSION_STATUS_FAILED';

export const UPDATE_USER_VERSION_STATUS_PENDING = 'UPDATE_USER_VERSION_STATUS_PENDING';
export const UPDATE_USER_VERSION_STATUS_SUCCESS = 'UPDATE_USER_VERSION_STATUS_SUCCESS';
export const UPDATE_USER_VERSION_STATUS_FAILED = 'UPDATE_USER_VERSION_STATUS_FAILED';
export const UPDATE_USER_VERSION = 'UPDATE_USER_VERSION';

export const IS_ADD_TEAM_UPDATED = 'IS_ADD_TEAM_UPDATED';

export const KR_LISTING_PENDING = 'KR_LISTING_PENDING';
export const KR_LISTING_SUCCESS = 'KR_LISTING_SUCCESS';
export const KR_LISTING_FAILED = 'KR_LISTING_FAILED';

export const FEEDBACK_REDIRECTION_DETAILS = 'FEEDBACK_REDIRECTION_DETAILS';
export const RESET_FEEDBACK_REDIRECTION_DETAILS = 'RESET_FEEDBACK_REDIRECTION_DETAILS';

export const CURRENT_ENGAGE_TAB = 'CURRENT_ENGAGE_TAB';

/**
 * ADMIN SETTINGS API URLs
 */
export const PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS = 'PERFORMANCE_REVIEW_ROLE_LIST_SUCCESS';
export const PERFORMANCE_REVIEW_ROLE_LIST_FAILED = 'PERFORMANCE_REVIEW_ROLE_LIST_FAILED';
export const PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS = 'PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS';
export const PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED = 'PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED';
// Custom Scale
export const QUESTION_TYPE_MASTER_SUCCESS = 'QUESTION_TYPE_MASTER_SUCCESS';
export const QUESTION_TYPE_MASTER_FAILED = 'QUESTION_TYPE_MASTER_FAILED';
export const MANAGE_SCALE_LIST_SUCCESS = 'MANAGE_SCALE_LIST_SUCCESS';
export const MANAGE_SCALE_LIST_FAILED = 'MANAGE_SCALE_LIST_FAILED';

/**
 * Perform Actions
 */

export const GET_ACCESS_TEAM_LIST_PENDING = 'GET_ACCESS_TEAM_LIST_PENDING';
export const GET_ACCESS_TEAM_LIST_SUCCESS = 'GET_ACCESS_TEAM_LIST_SUCCESS';
export const GET_ACCESS_TEAM_LIST_FAILED = 'GET_ACCESS_TEAM_LIST_FAILED';

export const GET_MULTIPLE_ASSESSMENT_PENDING = 'GET_MULTIPLE_ASSESSMENT_PENDING';
export const GET_MULTIPLE_ASSESSMENT_SUCCESS = 'GET_MULTIPLE_ASSESSMENT_SUCCESS';
export const GET_MULTIPLE_ASSESSMENT_FAILED = 'GET_MULTIPLE_ASSESSMENT_FAILED';
export const RESET_TEAM_ASSESSMENT = 'RESET_TEAM_ASSESSMENT';
/* My Assessment */

export const GET_EMPLOYEE_ASSESSMENT_PENDING = 'GET_EMPLOYEE_ASSESSMENT_PENDING';
export const GET_EMPLOYEE_ASSESSMENT_SUCCESS = 'GET_EMPLOYEE_ASSESSMENT_SUCCESS';
export const GET_EMPLOYEE_ASSESSMENT_FAILED = 'GET_EMPLOYEE_ASSESSMENT_FAILED';
export const RESET_EMPLOYEE_ASSESSMENT = 'RESET_EMPLOYEE_ASSESSMENT';

/* Performance cycle */

export const GET_PERFORMANCE_CYCLE_PENDING = 'GET_PERFORMANCE_CYCLE_PENDING';
export const GET_PERFORMANCE_CYCLE_SUCCESS = 'GET_PERFORMANCE_CYCLE_SUCCESS';
export const GET_PERFORMANCE_CYCLE_FAILED = 'GET_PERFORMANCE_CYCLE_FAILED';

/* Performance Alert */

export const GET_PERFORMANCE_ALERT_PENDING = 'GET_PERFORMANCE_ALERT_PENDING';
export const GET_PERFORMANCE_ALERT_SUCCESS = 'GET_PERFORMANCE_ALERT_SUCCESS';
export const GET_PERFORMANCE_ALERT_FAILED = 'GET_PERFORMANCE_ALERT_FAILED';

export const SET_PERFORMANCE_CYCLE = 'SET_PERFORMANCE_CYCLE';
export const UPDATE_ASSESSMENT_ALERT = 'UPDATE_ASSESSMENT_ALERT';

export const GET_FINAL_RATING_MASTER_PENDING = 'GET_FINAL_RATING_MASTER_PENDING';
export const GET_FINAL_RATING_MASTER_SUCCESS = 'GET_FINAL_RATING_MASTER_SUCCESS';
export const GET_FINAL_RATING_MASTER_FAILED = 'GET_FINAL_RATING_MASTER_FAILED';
export const GET_EMPLOYEES_FINAL_RATING = 'GET_EMPLOYEES_FINAL_RATING';

export const GET_EMPLOYEE_FINAL_RATING_PENDING = 'GET_EMPLOYEE_FINAL_RATING_PENDING';
export const GET_EMPLOYEE_FINAL_RATING_SUCCESS = 'GET_EMPLOYEE_FINAL_RATING_SUCCESS';
export const GET_EMPLOYEE_FINAL_RATING_FAILED = 'GET_EMPLOYEE_FINAL_RATING_FAILED';
export const UPDATE_COMPETENCY_RATING_TAB_VALUE = 'UPDATE_COMPETENCY_RATING_TAB_VALUE';
export const IS_DATA_LOADING = 'IS_DATA_LOADING';
export const GET_TEAM_USER_DATA_SUCCESS = 'GET_TEAM_USER_DATA_SUCCESS';

export const GET_RECOGNIZE_LIST_PENDING = 'GET_RECOGNIZE_LIST_PENDING';
export const GET_RECOGNIZE_LIST_SUCCESS = 'GET_RECOGNIZE_LIST_SUCCESS';
export const GET_RECOGNIZE_LIST_FAILED = 'GET_RECOGNIZE_LIST_FAILED';

export const GET_RECOGNIZE_BADGES_PENDING = 'GET_RECOGNIZE_BADGES_PENDING';
export const GET_RECOGNIZE_BADGES_SUCCESS = 'GET_RECOGNIZE_BADGES_SUCCESS';
export const GET_RECOGNIZE_BADGES_FAILED = 'GET_RECOGNIZE_BADGES_FAILED';

export const GET_FAME_BADGES_SUCCESS = 'GET_FAME_BADGES_SUCCESS';
export const GET_FAME_BADGES_PENDING = 'GET_FAME_BADGES_PENDING';
export const GET_FAME_BADGES_FAILED = 'GET_FAME_BADGES_FAILED';
/**
 * Request 1-On-1 related types
 */
export const GET_ONE_ON_ONE_REQUEST_LIST_PENDING = 'GET_ONE_ON_ONE_REQUEST_LIST_PENDING';
export const GET_ONE_ON_ONE_REQUEST_LIST_SUCCESS = 'GET_ONE_ON_ONE_REQUEST_LIST_SUCCESS';
export const GET_ONE_ON_ONE_REQUEST_LIST_FAILED = 'GET_ONE_ON_ONE_REQUEST_LIST_FAILED';

export const CURRENT_TAB_SELECTION = 'CURRENT_TAB_SELECTION';

/**Global Task data */
export const GLOBAL_TASK_PENDING = 'GLOBAL_TASK_PENDING';
export const GLOBAL_TASK_SUCCESS = 'GLOBAL_TASK_SUCCESS';
export const GLOBAL_TASK_FAILED = 'GLOBAL_TASK_FAILED';

/**Dashboard Global Task data */
export const GLOBAL_DASHBOARD_TASK_SUCCESS = 'GLOBAL_DASHBOARD_TASK_SUCCESS';

/**Global Task Source List data */
export const GLOBAL_TASK_SOURCE_PENDING = 'GLOBAL_TASK_SOURCE_PENDING';
export const GLOBAL_TASK_SOURCE_SUCCESS = 'GLOBAL_TASK_SOURCE_SUCCESS';
export const GLOBAL_TASK_SOURCE_FAILED = 'GLOBAL_TASK_SOURCE_FAILED';
export const GLOBAL_TASK_NOTIFICATION_REDIRECTION = 'GLOBAL_TASK_NOTIFICATION_REDIRECTION';
export const GLOBAL_TASK_CREATE_LINK_UN_SAVE_CHECK = 'GLOBAL_TASK_CREATE_LINK_UN_SAVE_CHECK';
export const GLOBAL_TASK_CREATE_POP_UP_DETAILS = 'GLOBAL_TASK_CREATE_POP_UP_DETAILS';

/**Global Task Drawer */
export const GLOBAL_TASK_AUTO_FOCUS = 'GLOBAL_TASK_AUTO_FOCUS';
/**
 * Signal-R new topic related action types
 */
export const IS_ONE_ON_ONE_REFRESH = 'IS_ONE_ON_ONE_REFRESH';
export const IS_DASHBOARD_REFRESH = 'IS_DASHBOARD_REFRESH';
export const IS_RECOGNIZE_REFRESH = 'IS_RECOGNIZE_REFRESH';

/**
 * Three Sixty Assessment constants
 */
export const GET_THREE_SIXTY_ASSESSMENT_API_PENDING = 'GET_THREE_SIXTY_ASSESSMENT_API_PENDING';
export const GET_THREE_SIXTY_ASSESSMENT_API_SUCCESS = 'GET_THREE_SIXTY_ASSESSMENT_API_SUCCESS';
export const GET_THREE_SIXTY_ASSESSMENT_API_FAILED = 'GET_THREE_SIXTY_ASSESSMENT_API_FAILED';
export const RESET_THREE_SIXTY_ASSESSMENT_DETAILS = 'RESET_THREE_SIXTY_ASSESSMENT_DETAILS';
export const SET_NOMINEE_DETAIL_FOR_REDIRECTION = 'SET_NOMINEE_DETAIL_FOR_REDIRECTION';
export const SET_PERFORMANCE_GOAL_FOR_REDIRECTION = 'SET_PERFORMANCE_GOAL_FOR_REDIRECTION';
export const SET_EMPLOYEE_DETAIL_FOR_REDIRECTION = 'SET_EMPLOYEE_DETAIL_FOR_REDIRECTION';

/**
 * Current Admin Org tab
 */
export const CURRENT_ORG_TAB = 'CURRENT_ORG_TAB';
export const CURRENT_SETTING_TAB = 'CURRENT_SETTING_TAB';
export const CURRENT_ROLES_PERMISSIONS_TAB = 'CURRENT_ROLES_PERMISSIONS_TAB';
/**alignment map new */
export const FETCH_ALIGN_DATA_PENDING = 'FETCH_ALIGN_DATA_PENDING';
export const FETCH_ALIGN_DATA_SUCCESS = 'FETCH_ALIGN_DATA_SUCCESS';
export const FETCH_ALIGN_DATA_FAILED = 'GET_THREE_SIXTY_ASSESSMENT_API_FAILED';

/**alignment map new */
export const FETCH_ALL_ALIGN_DATA_PENDING = 'FETCH_ALL_ALIGN_DATA_PENDING';
export const FETCH_ALL_ALIGN_DATA_SUCCESS = 'FETCH_ALL_ALIGN_DATA_SUCCESS';
export const FETCH_ALL_ALIGN_DATA_FAILED = 'FETCH_ALL_ALIGN_DATA_FAILED';

/**
 * SO Action Types
 */
export const GET_SO_PERMISSION_SUCCESS = 'GET_SO_PERMISSION_SUCCESS';
export const GET_SO_PERMISSION_FAILED = 'GET_SO_PERMISSION_FAILED';
export const OPEN_SO_CREATE_DRAWER = 'OPEN_SO_CREATE_DRAWER';

//*Ai
export const UPDATE_AI_KR = 'UPDATE_AI_KR';
export const REMOVE_AI_KR = 'REMOVE_AI_KR';
export const UPDATE_AI_EDIT_KR = 'UPDATE_AI_EDIT_KR';
export const REMOVE_AI_EDIT_KR = 'REMOVE_AI_EDIT_KR';

export const PRAISE_RECOGNIZE_ID = 'PRAISE_RECOGNIZE_ID';

export const DRAG_DISABLED = 'DRAG_DISABLED';

/**
 * New Global Task Action Types
 */
export const GET_GLOBAL_TASK_LIST = 'GET_GLOBAL_TASK_LIST';
export const CREATE_GLOBAL_TASK = 'CREATE_GLOBAL_TASK';
export const UPDATE_TASK_NAME = 'UPDATE_TASK_NAME';
export const UPDATE_TASK_DUE_DATE = 'UPDATE_TASK_DUE_DATE';

export const OKR_CREATION_INPROGRESS = 'OKR_CREATION_INPROGRESS';
export const OKR_CREATION_POPUP = 'OKR_CREATION_POPUP';
export const HIDE_PERFORMANCE_CYCLE = 'HIDE_PERFORMANCE_CYCLE';

/**
 * Default OKR Action Types
 */
export const SET_DEFAULT_OKR_NAME = 'SET_DEFAULT_OKR_NAME';
export const SET_CYCLE_DETAILS_DEFAULT_OKR = 'SET_CYCLE_DETAILS_DEFAULT_OKR';
export const FAIL_CYCLE_DETAILS_DEFAULT_OKR = 'FAIL_CYCLE_DETAILS_DEFAULT_OKR';
export const PENDING_CYCLE_DETAILS_DEFAULT_OKR = 'PENDING_CYCLE_DETAILS_DEFAULT_OKR';
export const SET_DESIGNATION_DETAILS_DEFAULT_OKR = 'SET_DESIGNATION_DETAILS_DEFAULT_OKR';
export const FAIL_DESIGNATION_DETAILS_DEFAULT_OKR = 'FAIL_DESIGNATION_DETAILS_DEFAULT_OKR';
export const PENDING_DESIGNATION_DETAILS_DEFAULT_OKR = 'PENDING_CYCLE_DETAILS_DEFAULT_OKR';
export const SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR = 'SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR';
export const FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR = 'FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR';
export const PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR = 'PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR';
export const OPEN_ADMIN_COPY_OKR = 'OPEN_ADMIN_COPY_OKR';
export const REFRESH_ADMIN_DEFAULT_OKR = 'REFRESH_ADMIN_DEFAULT_OKR';
export const SET_TOTAL_EMPLOYEE_COUNT = 'SET_TOTAL_EMPLOYEE_COUNT';
export const PAST_SELECTED_CYCLE = 'PAST_SELECTED_CYCLE';
export const ALIGN_SELECTED_CYCLE = 'ALIGN_SELECTED_CYCLE';

/**
 * User Assessment action types
 */
export const GET_SELF_ASSESSMENT_PENDING = 'GET_SELF_ASSESSMENT_PENDING';
export const GET_SELF_ASSESSMENT_SUCCESS = 'GET_SELF_ASSESSMENT_SUCCESS';
export const GET_SELF_ASSESSMENT_FAILED = 'GET_SELF_ASSESSMENT_FAILED';

/**
 * PIP
 */
export const RELOAD_PIP_LIST = 'RELOAD_PIP_LIST';
export const SET_PIP_FILTERS_LIST = 'SET_PIP_FILTERS_LIST';
export const SET_PIP_IMPROVEMENT_ID = 'SET_PIP_IMPROVEMENT_ID';
export const SET_FEEDBACK_IMPROVEMENT_ID = 'SET_FEEDBACK_IMPROVEMENT_ID';
export const SET_THREE_SIXTY_ID = 'SET_THREE_SIXTY_ID';
export const RELOAD_FEEDBACK_LIST = 'RELOAD_FEEDBACK_LIST';

export const GET_PIP_LISTING_FILTER_PENDING = 'GET_PIP_LISTING_FILTER_PENDING';
export const GET_PIP_LISTING_FILTER_SUCCESS = 'GET_PIP_LISTING_FILTER_SUCCESS';
export const GET_PIP_LISTING_FILTER_FAILED = 'GET_PIP_LISTING_FILTER_FAILED';

export const GET_PIP_ALERT_PENDING = 'GET_PIP_ALERT_PENDING';
export const GET_PIP_ALERT_SUCCESS = 'GET_PIP_ALERT_SUCCESS';
export const GET_PIP_ALERT_FAILED = 'GET_PIP_ALERT_FAILED';

export const GET_FEEDBACK_ALERT_PENDING = 'GET_FEEDBACK_ALERT_PENDING';
export const GET_FEEDBACK_ALERT_SUCCESS = 'GET_FEEDBACK_ALERT_SUCCESS';
export const GET_FEEDBACK_ALERT_FAILED = 'GET_FEEDBACK_ALERT_FAILED';

export const HIGHLIGHT_FEEDBACK_ID = 'HIGHLIGHT_FEEDBACK_ID';

export const RELOAD_DETAILS_PAGE = 'RELOAD_DETAILS_PAGE';

export const PERFORMANCE_IMPROVEMENT_PLAN_STATUS = 'PERFORMANCE_IMPROVEMENT_PLAN_STATUS';
export const RELOAD_FEEDBACK_CATEGORY_LIST = 'RELOAD_FEEDBACK_CATEGORY_LIST';

export const FEEDBACK_ONE_TO_ONE_SETTINGS_SUCCESS = 'FEEDBACK_ONE_TO_ONE_SETTINGS_SUCCESS';
export const FEEDBACK_ONE_TO_ONE_SETTINGS_FAILED = 'FEEDBACK_ONE_TO_ONE_SETTINGS_FAILED';

export const HIGHLIGHT_GOAL_FORM_ID = 'HIGHLIGHT_GOAL_FORM_ID';
export const RELOAD_GOAL_FORM_LIST = 'RELOAD_GOAL_FORM_LIST';
export const ALIGN_GOAL_PAGINATION_DATA = 'ALIGN_GOAL_PAGINATION_DATA';

export const HIGHLIGHT_GOAL_CYCLE_ID = 'HIGHLIGHT_GOAL_CYCLE_ID';
export const ALIGN_GOAL_CYCLE_PAGINATION_DATA = 'ALIGN_GOAL_CYCLE_PAGINATION_DATA';
export const ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA = 'ALIGN_GOAL_CYCLE_WORKFLOW_PAGINATION_DATA';
export const GOAL_REVIEW_PAGINATION_DATA = 'GOAL_REVIEW_PAGINATION_DATA';
export const SET_GOAL_CYCLE_FORM = 'SET_GOAL_CYCLE_FORM';

export const MANAGE_GOAL_TAB_COUNT_SUCCESS = 'MANAGE_GOAL_TAB_COUNT_SUCCESS';
export const MANAGE_GOAL_TAB_COUNT_FAILED = 'MANAGE_GOAL_TAB_COUNT_FAILED';

export const GET_PERFORMANCE_GOAL_CYCLE_SUCCESS = 'GET_PERFORMANCE_GOAL_CYCLE_SUCCESS';
export const GET_PERFORMANCE_GOAL_CYCLE_FAILED = 'GET_PERFORMANCE_GOAL_CYCLE_FAILED';
export const GET_PERFORMANCE_GOAL_CYCLE_DATA = 'GET_PERFORMANCE_GOAL_CYCLE_DATA';

export const GOAL_CONVERSATION_ALERT_SUCCESS = 'GOAL_CONVERSATION_ALERT_SUCCESS';
export const GOAL_CONVERSATION_ALERT_FAILED = 'GOAL_CONVERSATION_ALERT_FAILED';

/**Conversation Goal*/
export const FETCH_CONVERSATION_GOAL_PENDING = 'FETCH_CONVERSATION_GOAL_PENDING';
export const FETCH_CONVERSATION_GOAL_SUCCESS = 'FETCH_CONVERSATION_GOAL_SUCCESS';
export const FETCH_CONVERSATION_GOAL_FAILED = 'FETCH_CONVERSATION_GOAL_FAILED';
export const RESET_CONVERSATION_GOAL = 'RESET_CONVERSATION_GOAL';

export const SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT = 'SET_PERFORMANCE_GOAL_ATTACHMENT_COUNT';
export const PEOPLE_PROGRESS_FILTER = 'PEOPLE_PROGRESS_FILTER';
export const PEOPLE_PROGRESS_SELECTED_OPTION = 'PEOPLE_PROGRESS_SELECTED_OPTION';

export const HIGHLIGHT_GOAL_REVIEW_CYCLE_ID = 'HIGHLIGHT_GOAL_REVIEW_CYCLE_ID';
export const REFRESH_GOAL_REVIEW_LIST = 'REFRESH_GOAL_REVIEW_LIST';
export const SET_PERFORMANCE_REVIEW_CYCLE = 'SET_PERFORMANCE_REVIEW_CYCLE';
export const GET_PERFORMANCE_REVIEW_PENDING = 'GET_PERFORMANCE_REVIEW_PENDING';
export const GET_PERFORMANCE_REVIEW_SUCCESS = 'GET_PERFORMANCE_REVIEW_SUCCESS';
export const GET_PERFORMANCE_REVIEW_FAILED = 'GET_PERFORMANCE_REVIEW_FAILED';
export const SET_PERFORMANCE_REVIEW_DRUSERDATA = 'SET_PERFORMANCE_REVIEW_DRUSERDATA';
